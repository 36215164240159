@import url("https://use.typekit.net/oox5qmz.css");

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  background-color: black;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: clamp(14px, 1.3vw, 1.3vw);
  line-height: 1.25;
  font-family: neue-haas-unica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-weight: 300;
  font-style: normal;
  font-kerning: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  font-style: normal;
  font-size: clamp(14px, 1.3vw, 1.3vw);
  text-decoration: underline;
  margin: 0 0 1em 0;
}

p {
  margin: 0 0 1em 0;
}

em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

a { 
  text-decoration: underline;
  color: white;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
}

button {
  display: inline-grid;
  place-content: center;
  font-size: 1em;
  line-height: 1em;
  font-weight: 300;
  font-family: inherit;
  text-align: center;
  color: white;
  padding: 0.2em 0.5em 0.35em 0.5em;
  background: none;
  border: 1px solid white;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0 0 1em 0;

  li {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23fff'/%3E%3C/svg%3E") left 0.6em / 0.25em no-repeat;
    padding: 0 0 0 1em;
    margin: 0;
  }
}

.inputGroup {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  gap: 0.25em;
  margin-bottom: 1em;
}

input[type="text"],
input[type="email"],
input[type="datetime-local"],
textarea {
  background: none;
  border: 1px solid white;
  padding: 0.1em;
  font-size: 1em;
  font-family: inherit;
  font-weight: 300;
  color: white;

  &:focus {
    outline: white;
  }
}


input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
  position: absolute;
  left: 0;
  opacity: 0.01;
  z-index: 2;

  &:focus {
    outline: none;
  }
}

input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.5em;
  font-size: 1em;
  cursor: pointer;
}

input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.25em;
  width: 1em;
  height: 1em;
  border: 1px solid white;
  background: none;
}

input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
  content: '✕';
  position: absolute;
  top: 0.2em;
  left: 0.12em;
  font-size: 1em;
  line-height: 1;
  color: white;
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}