.fullSite {
  width: 100%;
  min-height: 100vh;

  .button.button--inline {
    display: inline-grid;
    background: none;
    border: none;
    padding: 0;
    color: white;
    font-size: 1em;
    font-family: inherit;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .nav {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 calc(5vw - 1rem);
    background: none;
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 0.5s ease;

    &.isVisible {
      opacity: 1;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      gap: 5px;

      li {
        padding: 0;
        margin: 0;
        display: inline-grid;
        place-content: center;
        background: none;
      }
    }

    &Button {
      display: inline-block;
      background: none;
      border: none;
      padding: 1rem;
      color: white;
      font-size: 1em;
      font-family: inherit;
      text-align: center;
      cursor: pointer;

      &.isCurrent {
        text-decoration: underline;
      }
    }
  }

  .header {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 5vw;
    display: grid;
    place-content: center;
    text-align: center;

    &Content {
      z-index: 1;
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: 1fr auto 1fr;
      gap: 10vh;

      .logo {
        grid-row: 2;
        margin: 0;
        opacity: 0;
        animation: fadeIn 3s forwards ease;

        &Image {
          display: block;
          margin: 0 auto;
          width: clamp(280px, 60vw, 60vw);
          height: auto;
        }
      }

      .introText {
        grid-row: 1;
        opacity: 0;
        animation: fadeIn 2s forwards ease;
        animation-delay: 1.5s;
        line-height: 1.35;
        margin: 0;
      }

      .splashFooter {
        grid-row: 3;
        position: relative;

        &First,
        &Second {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: grid;
          place-content: center;
          opacity: 0;
        }

        &First {
          animation: fadeInOut 3s forwards ease;
          animation-delay: 3s;
        }

        &Second {
          animation: fadeIn 2s forwards ease;
          animation-delay: 7s;
        }

        .artsCouncilLogo {
          display: block;
          width: clamp(150px, 12.5vw, 12.5vw);
        }

        .arrow {
          display: block;
          height: clamp(30px, 17vh, 300px);
        }
      }
    }
  }

  .main {
    position: relative;
    z-index: 1;
  }

  .columns {
    grid-column-start: 1;
    column-fill: auto;
    column-gap: 2vw;

    grid-column-end: 2;
    columns: 1 150px;

    @media (min-width: 768px) {
      grid-column-end: 3;
      columns: 2 150px;
    }

    @media (min-width: 992px) {
      grid-column-end: 3;
    }
  }

  .section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 5rem 5vw;
    display: grid;
    gap: 2vw;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--view {
      .book {
        margin-bottom: 2rem;
      }

      ul {
        padding: 0 4em 0 0;
      }

      .requirements p div {
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--credits {
      @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }

      h2 {
        text-decoration: none;
      }

      ul.creditList li {
        background: none;
        padding: 0;
      }

      .artsCouncilLogo {
        display: block;
        width: clamp(150px, 10.2vw, 10.2vw);
        margin-top: 1.5em;
      }

      .leverhulmeTrustLogo {
        display: block;
        width: clamp(120px, 11vw, 11vw);
        margin-top: 1.5em;
      }
    }

    &--privacy {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      font-size: clamp(14px, 1vw, 1vw);

      h3,
      h4 {
        font-size: 1em;
      }

      h4 {
        margin-bottom: 0;
        text-decoration: none;
        font-weight: 500;
      }

      .columns {
        grid-column-start: 1;
        grid-column-end: 5;
        columns: 1 150px;
        column-fill: auto;
        column-gap: 2vw;

        @media (min-width: 992px) {
          columns: 4 150px;
        }
      }
    }
  }

  .footer {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 5vw;

    .button {
      margin-right: 1rem;
    }

    .copyrightText {
      display: inline-block;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
