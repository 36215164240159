.landingSite {
  width: 100%;
  min-height: 100vh;
  color: white;

  main {
    position: relative;
  }

  .nav {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 calc(5vw - 1rem);
    background: none;
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 0.5s ease;

    &.isVisible {
      opacity: 1;
    }

    &List {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      gap: 5px;

      &Item {
        padding: 0;
        margin: 0;
        display: inline-grid;
        place-content: center;
      }
    }

    &Button {
      display: inline-block;
      background: none;
      border: none;
      padding: 1rem;
      color: white;
      font-size: 1rem;
      text-align: center;
      cursor: pointer;
    }
  }

  .header,
  .section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 5vw;
  }

  .header {
    display: grid;
    place-content: center;
    text-align: center;
    background: black;

    &Content {
      z-index: 1;
      display: grid;
      grid-auto-flow: row;
      gap: 17vh;

      .logo {
        grid-row: 2;
        margin: 0;
        opacity: 0;
        animation: fadeIn 3.5s forwards ease;

        &Image {
          display: block;
          margin: 0 auto;
          width: clamp(280px, 60vw, 900px);
          height: auto;
        }
      }
  
      .introText {
        grid-row: 1;
        opacity: 0;
        animation: fadeIn 2s forwards ease;
        animation-delay: 2.5s;
        line-height: 1.35;
      }

      .splashFooter {
        grid-row: 3;
        display: grid;
        align-content: center;
        opacity: 0;
        animation: fadeIn 2s forwards ease;
        animation-delay: 2.5s;

        grid-auto-flow: row;
        gap: 25px;

        @media (min-width: 768px) {
          grid-auto-flow: column;
          gap: 50px;
        }

        .comingSoon {
          margin: 0;
          padding-top: 5px;

          @media (min-width: 768px) {
            justify-self: end;
          }
        }

        .artsCouncilLogo {
          display: block;
          width: auto;
          margin: 0 auto;
          height: 25px;

          @media (min-width: 768px) {
            height: 45px;
            margin: 0;
          }
        }
      }


    }
  }

  .section--about {
    columns: 4 300px;
    column-gap: 1em;

    h2 {
      column-span: all;
    }
  }

  .footer {
    padding: 0 calc(5vw - 1rem);
    background: white;

    &List {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      gap: 5px;

      &Item {
        padding: 0;
        margin: 0;
        display: inline-grid;
        place-content: center;
      }
    }

    &Button {
      display: inline-block;
      background: none;
      border: none;
      padding: 1rem;
      color: rgba(black,0.5);
      font-size: 1rem;
      text-align: center;
      cursor: pointer;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
