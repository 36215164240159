.privacy {
  z-index: 1;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 5vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: clamp(14px, 1vw, 1vw);

  h3, h4 {
    font-size: 1em;
  }

  h4 {
    margin-bottom: 0;
    text-decoration: none;
    font-weight: 500;
  }

  .columns {
    grid-column-start: 1;
    grid-column-end: 5;
    columns: 1 150px;
    column-fill: auto;
    column-gap: 2vw;

    @media (min-width: 992px) {
      columns: 4 150px;
    }
  }
}
